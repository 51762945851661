import Head from "next/head";
import Overview from "~/components/Overview";
import New from "~/components/campaigns/New";
import { useCampaigns } from "~/providers/CampaignsProvider";
import Layout, { LayoutContent, LayoutMain } from "~/components/layout/Layout";
import NavBar from "~/components/layout/NavBar";
import Sidebar from "~/components/layout/Sidebar";

export default function HomePage() {
  const { campaigns } = useCampaigns();

  return (
    <Layout>
      <Head>
        <title>OpenQ DRM</title>
      </Head>
      <Sidebar />
      <LayoutMain>
        <NavBar />
        {campaigns.length > 0 ? (
          <div className="flex h-[100vh_-_36px] overflow-y-hidden w-full justify-center z-0 px-8">
            <div className="flex max-w-full grow flex-col justify-between">
              <Overview />
            </div>
          </div>
        ) : (
          <LayoutContent>
            <New />
          </LayoutContent>
        )}
      </LayoutMain>
    </Layout>
  );
}
